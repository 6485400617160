import { Link, Stack } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type Props = {
  listItems: Array<any>,
};

const DesktopList = (props: Props) => {
  const {
    listItems,
  } = props;
  const location = useLocation();

  const PageLists = listItems.map((item) => (
    <Link
      key={item.title}
      component={NavLink}
      to={item.link}
      variant="h5"
      underline="none"
      color="inherit"
      sx={{

        'marginLeft': '1rem',
        '&:hover': {
          color: (theme) => theme.palette.secondary.main,
        },
        ...(item.link === location.pathname && {
          textDecoration: 'underline',
          textDecorationColor: (theme) => theme.palette.secondary.main,
        }),
      }}
    >
      {item.title}
    </Link>
  ));

  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{
        marginLeft: '2rem',
      }}
    >
      {PageLists}
    </Stack>
  );
};

export default DesktopList;
