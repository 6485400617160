/* eslint-disable react/forbid-component-props */
import * as icons from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  appIcon: string,
  link: string,
};

const DynamicIcon = (props: Props) => {
  const { appIcon, link } = props;
  const IconComponent = (icons as any)[appIcon];
  const theme = useTheme();
  const location = useLocation();

  return (
    <IconComponent
      sx={{
        color: theme.palette.common.white,
        fontSize: '2.5rem',
        ...(link === location.pathname && {
          color: theme.palette.secondary.main,
        }),
      }}
    />
  );
};

export default DynamicIcon;
