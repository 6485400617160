import { CircularProgress, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import * as bender from '@tailosinc/bender-core';
import TailosTheme from '@tailosinc/bender-theme';
import React, { useCallback, useEffect, useState } from 'react';

import { getPermissions, logLogo } from '../../utils';
import ApolloProviderWrapper from '../ApolloProviderWrapper';
import App from '../App';
import ScrollToTop from '../ScrollToTop';
import registerMicroApps from './register-micro-app';

const RenderLoadingMetadata = React.memo(() => (
  <div className="status-container">
    <span className="status-message">
      <CircularProgress size={60} />
    </span>
  </div>
));

const RenderApp = React.memo(() => (
  <ApolloProviderWrapper>
    <ScrollToTop>
      <ThemeProvider theme={TailosTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ScrollToTop>
  </ApolloProviderWrapper>
));

const RenderWrapperComponent = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const loadAndRegisterApps = useCallback(() => {
    const perms = getPermissions(auth.getRoles());
    registerMicroApps(process.env.MICRO_APP_METADATA_RESOURCE, perms)
      .then(() => {
        setLoading(false);
        bender.setMainAppReady();
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [auth]);

  useEffect(() => {
    logLogo();
    loadAndRegisterApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? <RenderLoadingMetadata /> : <RenderApp />}
    </>
  );
};

export default RenderWrapperComponent;
