// import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import React, { useState } from 'react';

import UserMenu from '../../containers/UserMenu';
import { decodePayload } from '../../utils';

const UserProfileAvatar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const auth = useAuth();
  const user = decodePayload(auth.getJwtToken());
  const firstInitial = user.given_name.charAt(0);
  const secondInitial = user.family_name.charAt(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={(e) => handleClick(e)}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 'bold',
            }}
          >
            {`${firstInitial}${secondInitial}`}
          </Avatar>
        </IconButton>
      </Tooltip>
      <UserMenu
        visible
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default UserProfileAvatar;
