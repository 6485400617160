/* eslint-disable react/forbid-component-props */
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import * as bender from '@tailosinc/bender-core';
import React, { useCallback, useEffect, useState } from 'react';

import { getPermissions } from '../../utils';
import { DRAWER_MOUNT_POINT_NAME } from '../../utils/constants';
import DesktopList from './DesktopList';
import MobileList from './MobileList';
import TabletList from './TabletList';

const listItems = [
  {
    title: 'Summary',
    appIcon: 'Dashboard',
    link: '/summary',
  },
  {
    title: 'Robots',
    appIcon: 'Assessment',
    link: '/robots',
  },
];

type Props = {
  onClose: any,
};

const DynamicMenu = (props: Props) => {
  const { onClose } = props;
  const auth = useAuth();
  const [microAppsMetadata, setMicroAppMetadata] = useState([]);
  const TABLET_BREAKPOINT = useMediaQuery((theme:any) => theme.breakpoints.down('md'));
  const MOBILE_BREAKPOINT = useMediaQuery('(max-width:700px)');

  useEffect(() => {
    setMicroAppMetadata(bender.getAppsForMountPoint(DRAWER_MOUNT_POINT_NAME, {
      roles: getPermissions(auth.getRoles()),
      authorizers: null,
    }).sort((a: any, b: any) => a.order - b.order));
  }, [auth]);

  const DisplayListType = useCallback(() => {
    if (MOBILE_BREAKPOINT) {
      return (
        <MobileList listItems={listItems} />
      );
    }
    if (TABLET_BREAKPOINT) {
      return (
        <TabletList
          onClose={onClose}
          listItems={listItems}
        />
      );
    } else {
      return (
        <DesktopList listItems={listItems} />
      );
    }
  }, [TABLET_BREAKPOINT, onClose, MOBILE_BREAKPOINT]);

  // if (!microAppsMetadata || !microAppsMetadata.length) {
  //   return null;
  // }

  return (
    <DisplayListType />
  );
};

export default DynamicMenu;
