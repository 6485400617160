import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import DynamicIcon from './DynamicIcon';

type Props = {
  listItems: Array<any>,
  onClose: any,
};

const TabletList = (props: Props) => {
  const {
    listItems,
    onClose,
  } = props;
  const location = useLocation();

  const PageLists = listItems.map((item) => (
    <Box key={item.title}>
      <ListItem
        component={NavLink}
        to={item.link}
        sx={{
          color: (theme) => theme.palette.common.white,
          paper: '1rem',
          padding: '1rem',
          ...(item.link === location.pathname && {
            color: (theme) => theme.palette.secondary.main,
          }),
        }}
      >
        <ListItemIcon>
          <DynamicIcon appIcon={item.appIcon} link={item.link} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontSize: '1.5rem' }}
        >
          {item.title}
        </ListItemText>
      </ListItem>
      <Divider
        sx={{ backgroundColor: (theme) => theme.palette.common.white }}
      />
    </Box>
  ));

  return (
    <Box
      sx={{ width: 240 }}
      onClick={onClose}
      onKeyDown={onClose}
    >
      {PageLists}
    </Box>
  );
};

export default TabletList;
