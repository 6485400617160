import getPropertyListQuery from '../queries/getPropertyList';

export default (apolloClient: any) => async () => {
  const { error, data } = await apolloClient.query({
    query: getPropertyListQuery,
    fetchPolicy: 'network-only',
  });
  if (error) {
    throw error;
  }
  return data.properties;
};
