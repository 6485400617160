import {
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  MenuList,
  Select,
} from '@mui/material';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import useStyles from './styles';

const PropertyPickerDropdown = (props: any) => {
  const {
    loadPropertyList,
    onPropertyChange,
    // eslint-disable-next-line no-unused-vars
    loading,
    properties,
    property,
  } = props;

  const classes = useStyles();
  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      await loadPropertyList(true, auth);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdownOnClick = useCallback(async () => {
    await loadPropertyList(true, auth);
  }, [loadPropertyList, auth]);

  const handlePropertyChange = (a: any) => {
    onPropertyChange(a);
  };

  const propertyName = property ? property.name : '';

  return (
    <div>
      <FormControl>
        <Select
          value={propertyName}
          name="propertyName"
          displayEmpty
          renderValue={() => (
            <Chip
              clickable
              className={classes.chip}
              label={propertyName}
            />
          )}
          // className={classes.selectEmpty}
          onOpen={handleDropdownOnClick}
        >
          {loading
            ? (
              <MenuList className={classes.menuLoading}>
                <CircularProgress />
              </MenuList>
            )
            : (
              <MenuList>
                <MenuItem disabled>
                  Choose Property:
                </MenuItem>
                {properties
                  .filter((a: any) => (a.name !== propertyName))
                  .map((a: any) => (
                    <MenuItem
                      key={a.id}
                      value={a}
                      onClick={() => handlePropertyChange(a)}
                    >
                      {a.name}
                    </MenuItem>
                  ))}
              </MenuList>
            )}
        </Select>
      </FormControl>
    </div>
  );
};

PropertyPickerDropdown.defaultProps = {
  properties: [],
};

PropertyPickerDropdown.propTypes = {
  loadPropertyList: PropTypes.func.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  property: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default PropertyPickerDropdown;
