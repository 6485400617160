import {
  Drawer,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { ESCAPE_KEY_CODE, SIDEBAR_WIDTH } from '../../utils/constants';
import DynamicMenu from '../DynamicMenu';

type Props = {
  onClose: any,
  open: boolean,
};

const NavSidebar = (props: Props) => {
  const { onClose, open } = props;

  const closeOnEscape = useCallback((e) => {
    if (e.keyCode === ESCAPE_KEY_CODE && onClose) {
      e.preventDefault();
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', closeOnEscape);
    } else {
      document.removeEventListener('keydown', closeOnEscape);
    }
    return (document.removeEventListener('keydown', closeOnEscape));
  }, [closeOnEscape, open]);

  return (
    <Drawer
      sx={{
        'width': SIDEBAR_WIDTH,
        'flexShrink': 0,
        'backgroundColor': (theme) => theme.palette.background.default,
        '& .MuiDrawer-paper': {
          width: SIDEBAR_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: (theme) => theme.palette.secondary_background.main,
        },
      }}
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <DynamicMenu
        onClose={onClose}
      />
    </Drawer>
  );
};

export default NavSidebar;
