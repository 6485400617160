import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Backdrop,
  Badge,
  IconButton,
} from '@mui/material';
import { common } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

type Props = {
  microFrontEnd: any,
};

const mockNotifications = [
  {
    notificationName: 'Weekly Report',
    notificationDescription: 'Your weekly data report is available.',
    link: '',
    notificationCreated: '1654063065',
  },
  {
    notificationName: 'Account Request',
    notificationDescription: 'Your weekly data report is available.',
    link: '',
    notificationCreated: '1654149465',
  },
];

const NotificationIcon = (props: Props) => {
  const { microFrontEnd } = props;
  const [active, setActive] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const toggleDisplay = () => {
    if (active) {
      microFrontEnd.unmountApp('notification');
      setActive(false);
      return;
    }
    microFrontEnd.renderApp('notification');
    setActive(true);
  };

  const getNotificationQuery = () => mockNotifications;

  const notificationsLabel = (count: number) => {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  };

  const handleEsc = (event:any) => {
    if (event.key === 'Escape' && active) {
      toggleDisplay();
    }
  };

  useEffect(() => {
    // TODO look into graphQL polling to actively fetch notifications
    const notifications = getNotificationQuery();
  }, []);

  return (
    <>
      <IconButton
        aria-label={notificationsLabel(notificationCount)}
        onClick={toggleDisplay}
        onKeyDown={(e) => handleEsc(e)}
        sx={{
          color: common.white,
        }}
      >
        <Badge
          color='error'
          variant='dot'
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={active}
        onClick={toggleDisplay}
      />
    </>

  );
};

NotificationIcon.propTypes = {
  microFrontEnd: PropTypes.shape({
    renderApp: PropTypes.func.isRequired,
    unmountApp: PropTypes.func.isRequired,
  }),
};

export default NotificationIcon;
