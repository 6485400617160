import { combineReducers } from '@reduxjs/toolkit';

import activeSidebar from './activeSidebar';
import { propertyList, propertyListError, propertyListLoading } from './propertyList';
import propertyPicker from './propertyPicker';
import sidebarMicroApp from './sidebarMicroApp';
import user from './user';

const reducers = combineReducers({
  user,
  sidebarMicroApp,
  activeSidebar,
  propertyList,
  propertyListError,
  propertyListLoading,
  property: propertyPicker,
});

export default reducers;
