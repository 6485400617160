import { SecureRoute } from '@tailosinc/bender-auth-react-cognito-hook';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import RenderWrapper from './render-wrapper';

const Root = () => (
  <Routes>
    <Route
      path="*"
      element={(
        <SecureRoute>
          {RenderWrapper}
        </SecureRoute>
    )}
    />
  </Routes>
);

export default Root;
