import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  chip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.dark,
    fontWeight: 'bold',
  },
  menuLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
