import get from 'lodash/get';
import { connect } from 'react-redux';

import { removeActiveSidebar } from '../../actions';
import AppComponent from '../../components/App';

export const mapStateToProps = ({ activeSidebar, user }: any) => ({
  activeSidebar,
  logoutRequested: get(user, 'logout'),
});

export default connect(mapStateToProps, { removeActiveSidebar })(AppComponent);
