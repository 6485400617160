import {
  AppBar,
  Toolbar,
} from '@mui/material';
import React from 'react';

import DynamicMenu from '../DynamicMenu';

type Props = {
  onClose: any,
};

const MobileAppBar = (props: Props) => {
  const { onClose } = props;

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: (theme) => theme.palette.secondary_background.main,
        }}
      >
        <DynamicMenu
          onClose={onClose}
        />
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
