import {
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import DynamicIcon from './DynamicIcon';

type Props = {
  listItems: Array<any>,
};

const MobileList = (props: Props) => {
  const {
    listItems,
  } = props;
  const location = useLocation();

  const PageList = listItems.map((item) => (
    <Grid item key={item.title}>
      <IconButton
        component={NavLink}
        to={item.link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...(item.link === location.pathname && {
            color: (theme) => theme.palette.secondary.main,
          }),
        }}
      >
        <DynamicIcon appIcon={item.appIcon} link={item.link} />
        <Typography
          sx={{
            color: (theme) => theme.palette.common.white,
            ...(item.link === location.pathname && {
              color: (theme) => theme.palette.secondary.main,
            }),
          }}
          variant='subtitle2'
        >
          {item.title}
        </Typography>
      </IconButton>
    </Grid>
  ));

  return (
    <Grid
      container
      justifyContent='space-evenly'
    >
      {PageList}
    </Grid>
  );
};

export default MobileList;
