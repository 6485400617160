/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-dom-props */
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';

import SiteHeader from '../../containers/SiteHeader';
import Logout from '../Logout';
import ProfilePage from '../Profile';

// import useScript from './useScript';

type Props = {
  logoutRequested: boolean,
};
const ContentSeparator = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const App = (props: Props) => {
  const {
    logoutRequested,
  } = props;
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/summary');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  // useScript('ze-snippet', 'https://static.zdassets.com/ekr/snippet.js?key=a4d53842-ad81-4ab2-8b70-b0b2ad7a1b92');

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <SiteHeader />
        <div id="notification" />
      </Box>
      {
        logoutRequested && <Logout onLoad={auth.signOut} />
      }
      <ContentSeparator />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          overflowY: 'auto',
          height: '100%',
        }}
        id="main"
      >
        <Routes>
          <Route path="/profile" element={<ProfilePage />} />
        </Routes>
      </Container>

    </>
  );
};

export default App;
