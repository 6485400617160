/* eslint-disable react/forbid-component-props */
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import whiteLongFull from '../../../../assets/global/TAILOS-Full-Logo-Full-Color-White.svg';
import iconSolid from '../../../../assets/global/TAILOS-Icon-Solid-Full-Color.svg';
import PropertyPickerDropdownContainer from '../../containers/PropertyPickerDropdown';
import { getPermissions } from '../../utils';
import DynamicMenu from '../DynamicMenu';
import MobileAppBar from '../MobileAppBar';
import NavSidebar from '../NavSidebar';
import NotificationIcon from '../NotificationIcon';
import UserProfileAvatar from '../UserProfileAvatar';

type Props = {
  bender: any,
};

const SiteHeaderComponent = (props: Props) => {
  const {
    bender,
  } = props;
  const location = useLocation();
  const auth = useAuth();
  const TABLET_BREAKPOINT = useMediaQuery((theme:any) => theme.breakpoints.down('md'));
  const MOBILE_BREAKPOINT = useMediaQuery('(max-width:700px)');
  const [openDrawer, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const microAppsMetadataDrawer = bender.getAppsForMountPoint('drawer', {
    roles: getPermissions(auth.getRoles()),
  }).sort((a: any, b: any) => a.order - b.order);

  // const microAppsMetadataTopNavIcons = bender.getAppsForMountPoint('top-nav-icons', {
  //   roles: getPermissions(auth.getRoles()),
  // }).sort((a: any, b: any) => a.order - b.order);

  // // eslint-disable-next-line no-unused-vars
  // const [navCategory, setNavCategory] = useState();
  // // eslint-disable-next-line no-unused-vars
  // const [activeLink, setActiveLink] = useState(null); // this is for later
  // // if we have sub menu options and want to dispaly them at the top.

  useEffect(() => {
    const changeEventCallback = () => {
      const activeItem = location.pathname.length > 1
        && microAppsMetadataDrawer.filter((item: any) => location.pathname.startsWith(item.link))[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      activeItem && (
        setNavCategory(activeItem.category),
        setActiveLink(activeItem.link)
      );
    };
    window.addEventListener(bender.APP_CHANGE_EVENT, changeEventCallback);
    window.addEventListener(bender.NO_APP_CHANGE_EVENT, changeEventCallback);
    changeEventCallback();

    let returnValue;
    if (window.removeEventListener(bender.APP_CHANGE_EVENT, changeEventCallback) !== undefined) {
      returnValue = window.removeEventListener(bender.APP_CHANGE_EVENT, changeEventCallback);
    } else {
      returnValue = window.removeEventListener(bender.NO_APP_CHANGE_EVENT, changeEventCallback);
    }
    return returnValue;
  }, [location.pathname, microAppsMetadataDrawer, bender.APP_CHANGE_EVENT, bender.NO_APP_CHANGE_EVENT]);

  const DetermineLogoSize = useCallback(() => {
    if (TABLET_BREAKPOINT) {
      return (
        <Box
          component='img'
          sx={{
            float: 'left',
            height: '1.5em',
          }}
          alt="Tailos Icon Logo Full Color"
          src={iconSolid}
        />
      );
    } else {
      return (
        <Box
          component='img'
          sx={{
            float: 'left',
            height: '1rem',
          }}
          alt="Tailos Logo Full Color"
          src={whiteLongFull}
        />
      );
    }
  }, [TABLET_BREAKPOINT]);

  const ShowTableMenuIcon = useCallback(() => {
    if (MOBILE_BREAKPOINT) {
      return null;
    }
    if (TABLET_BREAKPOINT) {
      return (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
        >
          <MenuIcon sx={{ fontSize: '2rem' }} />
        </IconButton>
      );
    } else {
      return null;
    }
  }, [openDrawer, TABLET_BREAKPOINT, MOBILE_BREAKPOINT]);

  const ShowDesktopNavigationMenu = useCallback(() => {
    if (!TABLET_BREAKPOINT) {
      return (
        <DynamicMenu
          onClose={handleDrawerClose}
        />
      );
    } else {
      return null;
    }
  }, [TABLET_BREAKPOINT]);

  const ShowMobileAppBar = useCallback(() => {
    if (MOBILE_BREAKPOINT) {
      return (
        <MobileAppBar onClose={handleDrawerClose} />
      );
    } else {
      return null;
    }
  }, [MOBILE_BREAKPOINT]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: (theme) => theme.palette.secondary_background.main,
          }}
        >
          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item sx={{ display: 'flex' }}>
              <ShowTableMenuIcon />
              <IconButton
                component={NavLink}
                to="/summary"
              >
                <DetermineLogoSize />
              </IconButton>
              <ShowDesktopNavigationMenu />
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <NotificationIcon microFrontEnd={bender} />
              <PropertyPickerDropdownContainer />
              <UserProfileAvatar />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <NavSidebar
        open={openDrawer}
        onClose={handleDrawerClose}
      />
      <ShowMobileAppBar />
    </>
  );
};

export default SiteHeaderComponent;
