import {
  Help,
  Logout,
  People,
  Person,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  anchorEl: null | HTMLElement,
  open: boolean,
  onClose: any,
  logout: any,
};

const UserMenuComponent = (props: Props) => {
  const {
    anchorEl,
    open,
    onClose,
    logout,
  } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          'overflow': 'visible',
          'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          'mt': 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        component={NavLink}
        to="/user"
        // dont forget to change this
        state={{ data: 'userProfile' }}
      >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/user"
        state={{ data: 'teamManagement' }}
      >
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText>Manage Accounts</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        component={Link}
        target="_blank"
        href="https://support.tailos.com"
        rel="noreferrer"
      >
        <ListItemIcon>
          <Help />
        </ListItemIcon>
        <ListItemText>Help</ListItemText>
      </MenuItem>
      <MenuItem
        component={Button}
        onClick={() => logout()}
      >
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default UserMenuComponent;
